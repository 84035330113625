@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");

body {
  font-family: "Inter", sans-serif;
}

.form-styles {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 18px 0px #00000014;
  padding: 36px;
  border-radius: 10px;
}

.input-box {
  border: 1px solid #00000005;
  margin: 12px 0px;
  padding: 12px;
  border-radius: 20px;
  border: 2px solid #d5ffe4;
}

.blog-card {
  box-shadow: 0px 1px 8px 0px #00000012;
  border: 1px solid #f5f4f7;
  background: #fffbf2;
  border-radius: 10px;
}

input {
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  /* You can add more styles as needed */
}
/* Customize the scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #7c73c0;
}

::-webkit-scrollbar-thumb:hover {
  background: #97feed;
}
