.contact-btn {
  background-color: #130a46;
  box-shadow: 0px 4px 6px 0px #0000002e;
  padding: 12px 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 20px;
}

.form-background {
  border: 1px solid #f5f4f7;
  box-shadow: 0px 1px 8px 0px #00000012;
}
